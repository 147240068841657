<template>
  <div class="w-full space-y-4">
    <div class="flex flex-col bg-white rounded-lg p-5">
      <h1 class="font-semibold">Team Name</h1>

      <div class="mt-2 flex flex-col">
        <div
          class="w-full bg-red-500 mb-4 rounded-md p-2 px-3 text-white"
          v-if="teamCreateError"
        >
          There was an issue creating your team. If the problem persists, please
          contact support.
        </div>
        <div class="flex flex-row">
          <input
            type="text"
            name="text"
            id="team_name"
            class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md flex-1 mr-4"
            :class="{ 'bg-gray-50 text-gray-700': teamCreated }"
            v-model="teamName"
            :disabled="teamCreated"
          />
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            v-on:click="createTeam"
            v-if="!teamCreated"
          >
            Create Team
          </button>
          <div
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 cursor-not-allowed"
            v-if="teamCreated"
          >
            Team Created
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col bg-white rounded-lg p-5" v-if="teamCreated">
      <!-- v-if="teamCreated" -->
      <h1 class="font-semibold mb-2">Available Connections</h1>
      <div class="grid grid-cols-3 gap-4 pt-1">
        <!-- FB Brand: https://en.facebookbrand.com/facebookapp/assets/f-logo/?audience=landing -->
        <button
          class="h-32 flex flex-col bg-gray-800 rounded-md p-4 w-full text-center focus:outline-none text-white hover:bg-gray-700 transition-all"
          v-on:click="establishConnection('facebook')"
        >
          <div class="flex-1 w-full flex items-center content-center">
            <img
              class="w-12 h-12 mx-auto"
              src="https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png?w=512&h=512"
            />
          </div>
          <div class="mt-2 w-full">Facebook</div>
        </button>

        <!-- YT Brand: https://www.youtube.com/about/brand-resources/#logos-icons-colors -->
        <button
          class="h-32 flex flex-col bg-gray-800 rounded-md p-4 w-full text-center focus:outline-none text-white hover:bg-gray-700 transition-all"
          v-on:click="popupYouTubeVerify()"
        >
          <div class="flex-1 w-full flex items-center content-center">
            <img
              class="h-8 mx-auto"
              src="@/assets/3rd-party/yt_logo_rgb_dark.png"
            />
          </div>
          <div class="mt-2 w-full">YouTube</div>
        </button>

        <!-- Twitter Brand: https://en.facebookbrand.com/facebookapp/assets/f-logo/?audience=landing -->
        <button
          class="h-32 flex flex-col bg-gray-800 rounded-md p-4 w-full text-center focus:outline-none text-white hover:bg-gray-700 transition-all"
          v-on:click="establishConnection('twitter')"
        >
          <div class="flex-1 w-full flex items-center content-center">
            <img
              class="h-10 mx-auto"
              src="@/assets/3rd-party/2021-twitter-logo-blue.png"
            />
          </div>
          <div class="mt-2 w-full">Twitter</div>
        </button>
      </div>

      <h1 class="font-semibold mb-0 pt-4">Established Connections</h1>
      <span v-if="establishedEntities.length == 0"
        >You have not connected any accounts yet...</span
      >
      <div class="flex flex-col space-y-2 mt-2">
        <div
          class="w-full bg-gray-100 rounded-md flex flex-row items-center p-3 px-4 space-x-4 group"
          v-for="entity in establishedEntities"
          v-bind:key="entity.id"
        >
          <img
            class="w-4 h-4"
            src="https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png?w=512&h=512"
            v-if="entity.type == 'facebook'"
          />
          <img
            class="w-auto h-4"
            src="https://www.youtube.com/about/static/svgs/icons/brand-resources/YouTube-logo-full_color_dark.svg?cache=bb9b9c6"
            v-if="entity.type == 'youtube'"
          />
          <span class="flex-1">{{ entity.display_name }}</span>
          <button
            class="opacity-0 group-hover:opacity-100 transition-all text-red-600"
          >
            <i class="fad fa-minus-circle"></i>
          </button>
        </div>
      </div>
    </div>

    <Modal
      title="Select Destinations"
      acceptButtonText="Add Selection"
      :open="facebookSelectModalOpen"
      @onSuccess="attachFacebookEntries"
      @close="facebookSelectModalOpen = !facebookSelectModalOpen"
    >
      <div
        class="h-40 flex items-center content-center justify-center text-2xl text-gray-600 "
        v-if="loadingEntities"
      >
        <i class="fa fa-circle-notch fa-spin"></i>
      </div>

      <fieldset v-if="!loadingEntities">
        <div
          class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
        >
          <div
            class="relative flex items-start py-4"
            v-for="entity in availableEntities"
            v-bind:key="entity.id"
          >
            <div class="min-w-0 flex-1 text-sm">
              <label
                for="person-1"
                class="font-medium text-gray-700 select-none"
                >{{ entity.name }}</label
              >
            </div>
            <div class="ml-3 flex items-center h-5">
              <input
                id="person-1"
                name="person-1"
                type="checkbox"
                class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                v-model="entity.selected"
              />
            </div>
          </div>
        </div>
      </fieldset>
    </Modal>
    <Modal
      title="Connect YouTube"
      :open="connectYtOpen"
      @close="connectYtOpen = !connectYtOpen"
      :showAcceptButton="false"
    >
      <div class="flex items-center content-center">
        <img
          class="cursor-pointer"
          v-on:click="establishConnection('youtube')"
          src="@/assets/3rd-party/btn_google_signin_dark_normal_web.png"
        />
      </div>
      <h2 class="font-semibold mt-4">About</h2>
      <p>
        With over 1 billion users, YouTube is the biggest video viewing platform
        in the world. It remains the #1 platform for vloggers, gamers, and the
        like.
      </p>

      <p class="mt-4">
        By connecting your Google and YouTube account, you agree to
        <a href="#" class="text-blue-700">YouTube Terms of Service</a> and
        <a href="#" class="text-blue-700">Google's Privacy Policy</a>.
      </p>

      <p>
        <a
          href="https://myaccount.google.com/permissions"
          class="text-blue-700"
          target="_blank"
          >Revoke access</a
        >
      </p>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";

let images = {
  facebook: null,
};

export default {
  data() {
    return {
      teamName: null,

      teamCreated: false,
      teamInfo: null,
      teamCreateError: false,

      checkClosedInterval: null,

      facebookSelectModalOpen: false,
      loadingEntities: false,
      availableEntities: [],
      fbSelectedEntities: [],

      establishedEntities: [],
      connectYtOpen: false,
      images: images,
    };
  },
  components: { Modal },
  async mounted() {
    // this.fetchAvailableFacebookEntities()
    let res = await this.$http.get(
      process.env.VUE_APP_API_URL + "/app/available-connections"
    );
    console.log(res.body);
    this.refreshAttachedEntries();
  },
  methods: {
    async createTeam() {
      try {
        let res = await this.$http.post(
          process.env.VUE_APP_API_URL + "/app/team",
          {
            name: this.teamName,
          }
        );
        this.teamCreated = true;
        this.teamInfo = res.data;
        this.teamCreateError = false;
      } catch (e) {
        this.teamCreateError = true;
      }
    },

    establishConnection(platform) {
      let connectUrl = null;
      if (platform == "facebook") {
        connectUrl = process.env.VUE_APP_API_URL + "/social/connect/facebook";
      }
      if (platform == "youtube") {
        this.connectYtOpen = false;
        connectUrl =
          process.env.VUE_APP_API_URL +
          "/social/connect/youtube?teamId=" +
          this.teamInfo.id;
      }
      if (connectUrl == null) {
        alert("Social platform not supported yet...");
        return;
      }

      const newWindow = openOauthWindow(connectUrl);

      this.checkClosedInterval = setInterval(
        function() {
          if (newWindow.closed) {
            clearInterval(this.checkClosedInterval);

            if (platform == "facebook") {
              this.facebookSelectModalOpen = true;
              this.fetchAvailableFacebookEntities();
            }

            if (platform == "youtube") {
              this.refreshAttachedEntries();
            }

            if (platform == "twitter") {
              this.refreshAttachedEntries();
            }
          }
        }.bind(this),
        1000
      );
    },

    async fetchAvailableFacebookEntities() {
      this.loadingEntities = true;
      let res = await this.$http.get(
        process.env.VUE_APP_API_URL + "/app/connections/fb-accounts"
      );
      this.availableEntities = res.data;
      this.loadingEntities = false;
    },

    async attachFacebookEntries() {
      let selectedEntries = [];
      for (const entry of this.availableEntities) {
        if (entry.selected) {
          selectedEntries.push(entry.id);
        }
      }

      let res = await this.$http.post(
        process.env.VUE_APP_API_URL +
          "/app/team/" +
          this.teamInfo.id +
          "/entities",
        {
          ids: selectedEntries,
        }
      );
      this.refreshAttachedEntries();
      this.facebookSelectModalOpen = false;
    },

    async refreshAttachedEntries() {
      let res = await this.$http.get(
        process.env.VUE_APP_API_URL +
          "/app/team/" +
          this.teamInfo.id +
          "/entities"
      );
      this.establishedEntities = res.data;
    },

    async popupFix() {
      // const newWindow = openWindow("", "login");
      // this.login(newWindow);
      var new_window = window.open(
        process.env.VUE_APP_API_URL + "/social/connect/facebook",
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
      new_window.onload = function() {
        console.log("opened");

        this.onbeforeunload = function() {
          console.log("closed");
        };
      };
      var _self = this;
      this.checkClosedInterval = setInterval(function() {
        if (new_window.closed) {
          clearInterval(_self.checkClosedInterval);
          console.log("closed via interval");
        }
      }, 1000);
    },
    async login(newWindow) {
      // const url = await this.$store.dispatch("auth/fetchOauthUrl", {
      //   provider: "github",
      // });
      // openWindow(url, this.$t('login'))
      newWindow.location.href =
        "http://api.cloudseeder.local/social/connect/facebook";
    },
    /**
     * @param {MessageEvent} e
     */
    onMessage(e) {
      if (e.origin !== window.origin || !e.data.token) {
        return;
      }
      console.log(e);
      // this.$store.dispatch("auth/saveToken", {
      //   token: e.data.token,
      // });
      // this.$router.push({ name: "home" });
    },
    async popupYouTubeVerify() {
      this.connectYtOpen = true;
    },
  },
};

/**
 * @param  {Object} options
 * @return {Window}
 */
function openOauthWindow(url, title, options = {}) {
  if (typeof url === "object") {
    options = url;
    url = "";
  }
  options = { url, title, width: 600, height: 720, ...options };
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screen.top;
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    window.screen.height;
  options.self = "_self";
  options.left = width / 2 - options.width / 2 + dualScreenLeft;
  options.top = height / 2 - options.height / 2 + dualScreenTop;
  const optionsStr = Object.keys(options)
    .reduce((acc, key) => {
      acc.push(`${key}=${options[key]}`);
      return acc;
    }, [])
    .join(",");
  const newWindow = window.open(url, title, optionsStr);
  if (!newWindow) {
    console.log(
      "Please unblock popups in your browser settings to login with Github"
    );
  } else if (window.focus) {
    newWindow.focus();
  }
  return newWindow;
}
</script>
